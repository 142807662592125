html,
body,
#root {
  height: 100%;
}

.batman-switch {
  padding-top: 1.75rem;
  padding-bottom: 2rem;
  padding-left: 3.25rem;
}

.batman-switch-label {
  opacity: 0.65;
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}
