.batman-advanced-search {
  background-color: rgb(248, 249, 250);
  padding: 1rem;
  &-title {
    font-size: 1.5rem;
    padding: 1rem 0 2rem 1rem;
  }
}

.batman-advanced-search-actions {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  padding: 0.5rem;
  padding-bottom: 1.5rem;

  .btn {
    width: 8rem;
  }
}

.batman-advanced-search-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.batman-advanced-search-content-panel {
  width: 50%;
  height: 22rem;

  &-title {
    padding-bottom: 0.25rem;
    margin-bottom: 0.25rem;
    border-bottom: 1px lightgray solid;
  }

  &-content {
    height: 100%;

    .batman-dropdown-list-panel {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
}

.batman-dropdown-list-options {
  padding: 0.25em;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.125rem;
  flex-basis: 100%;
}

.batman-gap-half {
  gap: 0.5rem;
}

.batman-label {
}

.pointer {
  cursor: pointer !important;
}
