.batman-home {
  &-panel {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
  }

  &-title {
    font-size: 1.75em;
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 500;
    line-height: 1.2;
    text-decoration: none;
    color: unset;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    width: max-content;
  }

  &-paragraph {
    text-decoration: none;
    color: unset;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    width: max-content;
  }
}

.batman-filter {
  &-panel {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 1em;
    padding: 1em 0;
  }

  &-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    gap: 0.5em;
  }

  &-label {
    opacity: 0.8;
    // padding-left: 0.75em;
  }

  &-search {
    width: 100%;
    border-color: black;
  }

  &-text {
    border-color: black;
    width: 13em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &-button {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}
